<form [formGroup]="formAluno" (ngSubmit)="submit()">
    <fieldset class="form-group border p-2">
        <div class="form-row align-items-center">
            <div class="col-12">
                <div class="form-group row">
                    <label for="serie" class="col-md-2 col-form-label">Série *</label>

                    <div class="form-check form-check-inline" *ngFor="let number of [1,2,3,4,5]">

                        <input formControlName="serie" type="radio" class="form-check-input"
                            [attr.disabled]="listSeries.indexOf(number) < 0 || null" [value]="number"
                            id="radio_{{number}}">
                        <label class="form-check-label" for="radio_{{number}}">
                            {{number}}º ano
                        </label>
                    </div>
                    <label for="turmaSerie" class="col-md-2 col-form-label">Turma *</label>
                    <div class="col-md-3">
                        <input type="text" class="form-control col-md-6" formControlName="turmaSerie" style="text-transform: uppercase;" maxlength="2">
                        <div class="text-danger"
                            *ngIf="controlsForm.turmaSerie.invalid && (controlsForm.turmaSerie.dirty || controlsForm.turmaSerie.touched)">
                            <small *ngIf="controlsForm.turmaSerie.errors.required">
                                {{messageError.campoObrigatorio}}</small>
                            <small *ngIf="controlsForm.turmaSerie.errors?.maxlength" class="text-danger">
                                {{messageError.customCampoCaracteres(2)}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-row align-items-center">
            <div class="col-12">
                <div class="form-group row">
                    <label for="nome" class="col-md-2 col-form-label">Nome *</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control col-md-12" formControlName="nome" maxlength="150" (blur)="blurNome()">
                        <div class="text-danger"
                            *ngIf="controlsForm.nome.invalid && (controlsForm.nome.dirty || controlsForm.nome.touched)">
                            <small *ngIf="controlsForm.nome.errors.required">
                                {{messageError.campoObrigatorio}}</small>
                            <small *ngIf="controlsForm.nome.errors?.maxlength" class="text-danger">
                                {{messageError.customCampoCaracteres(150)}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-row align-items-center">
            <div class="col-12">
                <div class="form-group row">
                    <label for="nomeEtiqueta" class="col-md-2 col-form-label">Nome etiqueta *</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control col-md-6" formControlName="nomeEtiqueta" maxlength="50">
                        <div class="text-danger"
                            *ngIf="controlsForm.nomeEtiqueta.invalid && (controlsForm.nomeEtiqueta.dirty || controlsForm.nomeEtiqueta.touched)">
                            <small *ngIf="controlsForm.nomeEtiqueta.errors.required">
                                {{messageError.campoObrigatorio}}</small>
                            <small *ngIf="controlsForm.nomeEtiqueta.errors?.maxlength" class="text-danger">
                                {{messageError.customCampoCaracteres(50)}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-row align-items-center">
            <div class="col-12">
                <div class="form-group row">
                    <label for="pcd" class="col-md-2 col-form-label">Aluno com Deficiência</label>
                    <div class="form-check form-check-inline">
                        <input type="checkbox" class="form-check-input" id="alunoPcd" formControlName="alunoPcd"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="form-row float-right">
                <button type="submit" class="btn btn-success ml-1 float-right" [disabled]="!formAluno.valid" *ngIf="!idAluno"
                    onclick="this.blur()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-plus" viewBox="0 0 16 16">
                        <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    Incluir
                </button>
                <button type="submit" [disabled]="!formAluno.valid" *ngIf="idAluno" onclick="this.blur()"
                    class="btn btn-success ml-2 float-right">
                    <svg height="1em" viewBox="0 0 512.007 512.007" width="1em" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="m511.927 126.537c-.279-2.828-1.38-5.666-3.315-8.027-.747-.913 6.893 6.786-114.006-114.113-2.882-2.882-6.794-4.395-10.612-4.394-9.096 0-329.933 0-338.995 0-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zm-166.927-96.534v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113h-139c-8.271 0-15-6.729-15-15v-98zm64 291h-218v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92c0-19.555 0-157.708 0-180 0-24.813-20.187-45-45-45h-188c-24.813 0-45 20.187-45 45v180h-52c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787l104.213 104.214z" />
                    </svg>
                    Salvar
                </button>
                <button type="button" class="btn btn-warning ml-2 float-right" (click)="limparForm()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-clockwise"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                        <path
                            d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                    </svg>
                    Limpar
                </button>
            </div>
        </div>
    </fieldset>
</form>
<div class="col-12 mb-5">
    <button id="btnVoltar" class="btn btn-light" type="button" (click)="backTab()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                                  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path fill-rule="evenodd"
              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
        </svg>
        Voltar
      </button>
    <button type="button" class="btn btn-light mb-5 float-right" placement="bottom" (click)="fechar()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
          </svg>
          Finalizar turma
    </button>
  </div>
<table class="table table-condensed table-hover">
    <thead>
        <tr>
            <th style="width: 5%;">#</th>
            <th style="width: 8%;">Série</th>
            <th style="width: 8%;">Turma</th>
            <th>Nome completo</th>
            <th style="width: 25%;">Nome etiqueta</th>
            <th style="width: 10%;">PCD</th>
            <th style="width: 5%;"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let aluno of listAluno; let i = index">
            <td>{{i+1}}</td>
            <td>{{aluno.serie}}</td>
            <td>{{aluno.turmaSerie}}</td>
            <td>{{aluno.nome}}</td>
            <td>{{aluno.nomeEtiqueta}}</td>
            <td>{{ getPcdStatus(aluno) }}</td>
            <td>
                <div class="dropdown">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuActions"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuActions">
                        <button type="button" class="btn btn-outline-secondary dropdown-item" placement="bottom"
                            (click)="alterar(aluno.id)">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z">
                                </path>
                            </svg>
                            Editar
                        </button>
                        <div class="dropdown-divider"></div>
                        <button type="button" class="btn btn-outline-secondary dropdown-item" placement="bottom"
                            (click)="remover(aluno.id)">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path fill-rule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                            </svg>
                            Remover
                        </button>
                    </div>
                </div>
            </td>
        </tr>
        <tr *ngIf="listAluno.length == 0">
            <td colspan="6" align="center">
                Nenhum registro encontrado.
            </td>
        </tr>
    </tbody>
</table>
<div class="col-12 mb-5">
    <button id="btnVoltar" class="btn btn-light" type="button" (click)="backTab()">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                                  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path fill-rule="evenodd"
              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
        </svg>
        Voltar
      </button>
    <button type="button" class="btn btn-light mb-5 float-right" placement="bottom" (click)="fechar()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
          </svg>
          Finalizar turma
    </button>
  </div>