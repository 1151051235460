import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Aluno } from 'src/app/models/aluno';
import { AgendamentoService } from 'src/app/services/agendamento.service';
import { AlunoService } from 'src/app/services/aluno.service';
import { MessageService } from 'src/app/services/message.service';
import { TurmaService } from 'src/app/services/turma.service';
import { UtilityService } from 'src/app/services/utility.service';
import { MessageError } from 'src/app/utils/message-error';
import { TurmaTabEnum } from 'src/app/utils/turma-tab.enum';

@Component({
  selector: 'app-turma-aluno',
  templateUrl: './turma-aluno.component.html',
  styleUrls: ['./turma-aluno.component.css']
})
export class TurmaAlunoComponent implements OnInit {


  @Input() idAgendamento: number;
  @Output() accessNextTab = new EventEmitter<TurmaTabEnum>();

  messageError: MessageError;
  formAluno: FormGroup;
  validarPrazo: boolean;

  constructor(
    private agendamentoService: AgendamentoService,
    private alunoService: AlunoService,
    private turmaService: TurmaService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private utils: UtilityService
  ) { }

  listSeries: number[] = new Array();
  listAluno: Aluno[] = new Array();
  idAluno: number;

  ngOnInit(): void {

    this.formAluno = this.fb.group({
      serie: new FormControl('', [Validators.required]),
      turmaSerie: new FormControl('', [Validators.maxLength(2), Validators.required]),
      nome: new FormControl('', [Validators.maxLength(150), Validators.required]),
      nomeEtiqueta: new FormControl('', [Validators.maxLength(50), Validators.required]),
      alunoPcd: new FormControl(false)
    })

    this.agendamentoService.listSeriesAgendamento(this.idAgendamento).subscribe(
      result => this.listSeries = result,
      errorResult => this.messageService.tratarMensagemErro('Erro ao buscar as séries do agendamento', true, errorResult, () => { }));

    this.listAlunos();

    this.messageError = new MessageError();
    
    this.validarPrazo = true;
    
    if(!(this.utils.getDtnUserWrapper().isOperadorEscola())){
        this.validarPrazo = false;
    }
    
  }

  listAlunos(): void {
    this.alunoService.findAllByAgendamento(this.idAgendamento, this.utils.getDtnUserWrapper().isOperadorEscola()).subscribe(
      result => this.listAluno = result,
      errorResult => this.messageService.tratarMensagemErro('Erro ao buscar os alunos do agendamento', true, errorResult, () => { }))
  }

  submit(): void {

    const alunoForm: Aluno = this.formAluno.value;
    alunoForm.idAgendamento = this.idAgendamento;
    alunoForm.usuario = this.utils.getDtnUserWrapper().getNomeUsuario();
    
    if (this.idAluno) {
      this.alunoService.update(this.idAluno, alunoForm, this.validarPrazo)
        .subscribe(response => {
          this.messageService.tratarMensagemSucesso('Aluno alterado com sucesso', true, () => { this.cleanAndRefresh(); });
        },
          errorResponse => {
            this.messageService.tratarMensagemErro('Erro ao alterar o aluno', true, errorResponse, () => { });
          })
    }
    else {
      this.alunoService.create(alunoForm, this.validarPrazo)
        .subscribe(response => {
          this.messageService.tratarMensagemSucesso('Aluno cadastrado com sucesso', false, () => {
             if(response.numeroVisitantes == 44){
                this.messageService.aviso('AVISO às escolas localizadas em CAMPO GRANDE/MS: \nO número de alunos cadastrados EXCEDEU o limite para o transporte. \nO Detran/MS somente se responsabiliza pelo transporte de 41 alunos e 02 acompanhantes. \nOs alunos e acompanhantes excedentes são de responsabilidade da escola.', 
                () => { this.cleanAndRefresh(); });
             } else {
                this.cleanAndRefresh(); 
             }
          });
        },
          errorResponse => {
            this.messageService.tratarMensagemErro('Erro ao cadastrar o aluno', true, errorResponse, () => { });
          });
    }
  }

  private cleanAndRefresh(): void {
    this.limparForm();
    this.listAlunos();
  }

  limparForm() {

    this.formAluno.patchValue({
      serie: '',
      turmaSerie: '',
      nome: '',
      nomeEtiqueta: '',
      alunoPcd: false
    });

    this.idAluno = null;

    this.cleanValidatorsError();
  }

  alterar(idAluno: number) {
    this.idAluno = idAluno;


    const listFiltered = this.listAluno.filter(a => a.id == this.idAluno);
    if (listFiltered?.length)
      this.formAluno.patchValue({
        serie: listFiltered[0].serie,
        turmaSerie: listFiltered[0].turmaSerie,
        nome: listFiltered[0].nome,
        nomeEtiqueta: listFiltered[0].nomeEtiqueta,
        alunoPcd: listFiltered[0].alunoPcd
      })
    else {
      this.messageService.aviso('Erro ao alterar o aluno.', () => { this.cleanAndRefresh() });
    }
  }

  remover(idAluno: number) {

    this.messageService.confirmacao('Tem certeza que deseja exluir esse aluno?',
      () => {
        this.alunoService.delete(idAluno, this.validarPrazo)
          .subscribe(response => {
            this.messageService.tratarMensagemSucesso('Aluno excluído com sucesso', true, () => { this.cleanAndRefresh(); });
          },
            errorResponse => {
              this.messageService.tratarMensagemErro('Erro ao excluir o aluno', true, errorResponse, () => { });
            });
      },
      () => {
        this.messageService.aviso('Operação cancelada!')
      })
  }

  fechar(): void {
    this.messageService.confirmacao(`Tem certeza que deseja finalizar a turma?`,
      () => {
        this.turmaService.close(this.idAgendamento, this.utils.getDtnUserWrapper().getNomeUsuario())
          .subscribe(response => {
            if(response.numeroVisitantes >= 43) {
              this.messageService.aviso('AVISO às escolas localizadas em CAMPO GRANDE/MS: \nO número de alunos cadastrados EXCEDEU o limite para o transporte. \nO Detran/MS somente se responsabiliza pelo transporte de 41 alunos e 02 acompanhantes. \nOs alunos e acompanhantes excedentes são de responsabilidade da escola.', 
              () => { this.print(this.idAgendamento) });

            }else{
              this.messageService.confirmacao('Turma finalizada com sucesso! Deseja baixar as autorizações?', 
                () => { this.print(this.idAgendamento) }, 
                () => { this.router.navigate(["/turma"]) });
            }
          },
            errorResponse => {
              this.messageService.tratarMensagemErro('Erro ao fechar a turma. Tente novamente mais tarde.', true, errorResponse, () => { });
            });
      },
      () => {
        this.messageService.aviso('Operação cancelada!')
      })
  }
  
  getPcdStatus(aluno: Aluno): string {
  return aluno.alunoPcd ? 'PCD' : '';
}
  
  blurNome() {
    if (!this.formAluno.value.nomeEtiqueta)
      this.formAluno.patchValue({
        nomeEtiqueta: this.formAluno.value.nome.split(' ')[0]
      })
  }

  backTab() : void {
    
    this.accessNextTab.emit(TurmaTabEnum.Alunos);
  }

  private cleanValidatorsError() {
    this.formAluno.markAsPristine();
    this.formAluno.markAsUntouched();
  }

  get controlsForm() { return this.formAluno.controls; }

  print(agendamentoId: number) {
    this.agendamentoService.printAutorizacoes(this.idAgendamento).subscribe((response) => {
      let blob = new Blob([response], { type: 'application/pdf' });
      let downloadURL = window.URL.createObjectURL(blob);

      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = "Autorizacoes.pdf";
      link.click();
    }, (errorResponse) => {
      this.messageService.tratarMensagemErro(
        "Não foi possível gerar a impressão",
        true,
        errorResponse,
        () => {});
    });
  }

}
