import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AgendamentoEscola } from "src/app/models/agendamento-escola";
import { Escola } from "src/app/models/escola";
import { AgendamentoService } from "src/app/services/agendamento.service";
import { DominioService } from "src/app/services/dominio.service";
import { EscolaService } from "src/app/services/escola.service";
import { MessageService } from "src/app/services/message.service";
import { Dominio } from 'src/app/models/dominio';
import { DtnAuthUserWrapper } from 'src/app/utils/dtn-auth-user-wrapper';
import { UtilityService } from 'src/app/services/utility.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';


@Component({
    selector: 'app-agendamento-list',
    templateUrl: './agendamento-list.component.html',
    styleUrls: ['./agendamento-list.component.css']
})
@Injectable()
export class AgendamentoListComponent implements OnInit {
     
    filtro: FormGroup;

    agendamentos: AgendamentoEscola[];
    escolas: Escola[];
    escolaSelecionada: number;

    listDominioAgendamentoSituacao: Dominio[];

    page: number;
    totalSize: number;
    pageSize: number;

    dtnUserWrapper: DtnAuthUserWrapper;
    isOperadorEscola: boolean;
    idMes: number;
    data:Date;
    constructor(
        private fb: FormBuilder,
        private agendamentoService: AgendamentoService,
        private messageService: MessageService,

        private escolaService: EscolaService,
        private dominioService: DominioService,
        private utils: UtilityService,
        private router: Router) {

        this.dtnUserWrapper = utils.getDtnUserWrapper();
        this.isOperadorEscola = this.dtnUserWrapper.isOperadorEscola();

        this.agendamentos = new Array();
        this.escolaSelecionada = this.dtnUserWrapper.getCodigoEscola();
        this.page = 1;
        this.totalSize = 0;
        this.pageSize = 7;
        this.idMes = 11;
//        this.data = "Teste"
        console.log('agendamento construtor', this.dtnUserWrapper, this.isOperadorEscola);
    }

    ngOnInit(): void {
        this.filtro = this.fb.group({
            escolaId: [{value: this.dtnUserWrapper && this.dtnUserWrapper.isOperadorEscola() ? this.dtnUserWrapper.getInstituicaoEscola().getId() : '', disabled: this.dtnUserWrapper && this.dtnUserWrapper.isOperadorEscola()}],
            codigoInep: [''],
            dataAgendada: [''],
            tipo: [''],
            situacao: ['']
        });
        let escolaFiltro = {
            nome: '', dominioIdTipo: null, codigo: this.escolaSelecionada, dominioIdSituacao: null,
            isOperadorEscola: this.dtnUserWrapper.isOperadorEscola() ? '1' : '0'
        };
        this.escolaService.find(escolaFiltro, 0, 1000)
            .subscribe(data => {
                this.escolas = data.list;
            }, error => {
                this.messageService.tratarMensagemErro('Não foi possível recuperar as escolas', true, error, () => { });
            });

        this.dominioService.getDominioAgendamentoSituacao().subscribe(response => {
            this.listDominioAgendamentoSituacao = response;
        }, error => {
            console.log('AgendamentoListComponent.ngOnInit() - getDominioAgendamentoSituacao(): erro: ', error);
            this.messageService.tratarMensagemErro('Não foi possível recuperar a situação dos agendamentos', true, error, () => { });
        });

        this.buscar();
    }

    buscar() {
        const filters = this.filtro.getRawValue();
        this.agendamentoService.list(filters.escolaId, filters.dataAgendada, (this.page - 1), this.pageSize, filters.situacao)
            .subscribe(response => {
                this.agendamentos = response.list;
                this.totalSize = response.totalSize;
            }, err => {
                this.messageService.tratarMensagemErro('Não foi possível recuperar os agendamentos',
                    true, err, () => { });
            });
    }

    confirmarCancelamento(agendamento: AgendamentoEscola) {
        this.messageService.confirmacao("Tem certeza que deseja cancelar o agendamento?",
            () => { this.cancelar(agendamento); }, () => { });
    }

    cancelar(agendamento: AgendamentoEscola) {
        this.agendamentoService.cancel(agendamento.agendamentoId, 
            this.utils.getDtnUserWrapper().getNomeUsuario(), 
            this.utils.getDtnUserWrapper().isOperadorEscola())
            .subscribe(response => {
                this.buscar();
            }, err => {
                this.messageService.tratarMensagemErro("Não foi possível cancelar o agendamento.", true, err, () => { });
            });
    }

    //alterado para utilizar o proprio prazo trazido do backend
    podeCancelar(agendamento: AgendamentoEscola): boolean {
        return Number(String(agendamento.prazo).replace(/[^0-9]/g,"")) > 0;
    }
    
     formatDateToDDMMYYYY(date: Date): string {
        const day = String(date.getDate() + 1).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());

        return `${day}/${month}/${year}`;
     }
    
    buscarData(agendamento){
        const dataAgendamento = new Date(agendamento.dataAgendada);
        console.log("Data Agebdamento" + dataAgendamento)
        const dataAtual = new Date();
        let mesAtual = dataAtual.getMonth() + 1;
        mesAtual =  mesAtual - 8 ;
        this.idMes = this.idMes + mesAtual;
        console.log(`O mês atual em número é: ${this.idMes}`);
        console.log(`Data agendamento: ${agendamento.dataAgendada}`);
        this.data = agendamento.dataAgendada
        console.log(`Data: ${this.data}`);
        console.log(`Data: ${agendamento.periodoDescricao}`); 
        if(agendamento.situacaoDescricao != "CANCELADO"){
        this.router.navigate(['/agendamento/visualizar2/', this.idMes,this.formatDateToDDMMYYYY(dataAgendamento) ,agendamento.periodoDescricao,agendamento.agendamentoId]); 
        }
    }
        
        getPrazoFormatado(agendamento: AgendamentoEscola): string {
        return agendamento.situacaoDescricao === 'AGENDADO' ? '-' : agendamento.prazo;
    }
   
}
