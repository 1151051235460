<div class="jumbotron" style="text-align: center;">
  <h3>Turma</h3>
</div>
<div class="jumbotron" style="text-align: left;">
  <span>
    <p>ATENÇÃO!</p>
    <p>
      Será possível cadastrar até 2 acompanhantes por turma.<br>
      Caso exista algum aluno que necesite de um profissional de apoio, será permitido cadastrar um acompanhante por aluno.<br>
      Cada turma pode conter alunos do 1º ao 5º ano com no mínimo 25 alunos e no máximo 43 visitantes,<br>
      incluíndo alunos, acompanhantes e profissionais de apoio.<br>
      Caso deseje cadastrar mais de 43 visitantes, será necessário cadastrar outra turma em outro período, ou data.
    </p>
  </span>
</div>

<ngb-tabset #tabset="ngbTabset" [activeId]="tabAtiva" [destroyOnHide]="false" (tabChange)="onTabChange($event)">
  <ngb-tab title="Acompanhante" id="acompanhante">
    <ng-template ngbTabContent>
      <app-acompanhante-profissional
        [agendamentoId]="agendamentoId"
        [tabType]="tabEnum.Acompanhante"
        (accessNextTab)="handleAccessNextTab($event)">
      </app-acompanhante-profissional>
    </ng-template>
  </ngb-tab>
  <ngb-tab title="Profissional de Apoio" id="profissionalApoio">
    <ng-template ngbTabContent>
      <app-acompanhante-profissional
        [agendamentoId]="agendamentoId"
        [tabType]="tabEnum.ProfissionalApoio"
        (accessNextTab)="handleAccessNextTab($event)">
      </app-acompanhante-profissional>
    </ng-template>
  </ngb-tab>
  <ngb-tab title="Alunos" id="alunos">
    <ng-template ngbTabContent>
      <app-turma-aluno [idAgendamento]="agendamentoId" (accessNextTab)="handleAccessNextTab($event)">
      </app-turma-aluno>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
